.Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 678px) {
    font-size: large;
    line-height: normla;
  }
  
}

  .Input:focus, .Message:focus{
    outline: none;
    border: 3px solid rgb(250, 181, 53);
  }
  
  .Message {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(90, 90, 90);
    margin-bottom: 10px;
    padding: 8px;
    min-height: 200px;
    resize: none;
  }


.QuizStatus {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.QuizStatusText {

  margin-right: 5px;
}

.Smiley {
  animation: bounce 0.5s;
}

.Smiley.happy {
  color: #4a7665;  /* Green color for happy smiley */
}

.Smiley.sad {
  color: #d32f2f;  /* Red color for sad smiley */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


.Happy_label {
  margin: 0 10px 0 0;
}

.Quiz_link {
  color: green;
  text-decoration: none;
}

.Message {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(90, 90, 90);
  border-radius: 5px;;
  margin-bottom: 10px;
  padding: 8px;
  min-height: 200px;
  resize: none;
}

.Datenschutz {
  margin-bottom: 5px;
  font-size: small;
  display: flex;
  flex-direction: row;
  @media (max-width: 678px) {
    flex-direction: row;
  }
}

.Datenschutz a, .Datenschutz label{
  flex: 1;
  color: rgb(47, 72, 88);
  margin: 0 5px 3px 5px;
}

.Send {
  margin: 10px 0;
  background-color: rgb(250, 181, 53);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  align-self: flex-start;
}

.Send:hover {
  color: black;
}

label {
  display: block;
  margin-bottom: 5px;
}

.AgeGenderContainer {
  display: flex;
  align-items: center;
}

.Row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0px;
  @media (max-width: 678px) {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.Field {
  flex: 1;
  margin-right: 20px;
  @media (max-width: 678px) {
    margin-right: 0;
  }
}

.Field:last-child {
  margin-right: 0;
}

.Input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(103, 102, 102);
  border-radius: 5px;;
  margin-bottom: 30px;
  padding: 8px;
}

.Input:focus, .Message:focus {
  outline: none;
  border: 3px solid rgb(250, 181, 53);
}

.CheckboxField {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.CheckboxField input[type="checkbox"] {
  margin-right: 0.5rem;
}

.InquirySection {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.Happy_label {
  margin-right: 10px;
}

@media (max-width: 678px) {
label {
  margin-bottom: 1px;
}
}

.Send:disabled {
  background-color: rgb(203, 203, 203);
  cursor: not-allowed;
}

.Send:disabled:hover {
  color: white;
}
