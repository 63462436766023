.visionPoint .iconContainer img {
  width: 300px; /* Adjust width as necessary */
  height: 300px; /* Adjust height as necessary */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
}

.description {
  text-align: justify;
}

.visionPoint {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  border-radius: 15px; 
  padding: 20px; 
  gap: 20px; 
}

.visionPoint.reverse {
  flex-direction: row-reverse;
}

.iconContainer {
  flex: 0 0 auto;
  margin: 0 20px;
}

.icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0,0,0,.5),
              -2px -2px 4px rgba(0,0,0,.5);
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.pointTitle {
  margin-top: 0;
  margin-bottom: 10px;
}


.description {
  text-align: justify;;
}

/* Responsive styles for mobile devices */
@media (max-width: 600px) {
  .visionPoint {
    flex-direction: column;
    align-items: center;
  }
  /* Override reverse class on mobile devices */
  .visionPoint.reverse {
    flex-direction: column; /* Ensure it remains column and not column-reverse */
  }
  .visionPoint .iconContainer img {
    width: 100%;
    height: auto;
  }
  .iconContainer {
    margin: 0;
  }
  .info {
    text-align: center;
  }
}
