.footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
  padding: 0;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 7.5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #333;
  @media (min-width: 678px) {
    width: 70%;
  }
}

.footer_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;           /* Ensure Flexbox is used */
  flex-direction: row;     /* Set direction to horizontal */
  justify-content: center; /* Center the links horizontally */
  align-items: center;     /* Center the links vertically */
  flex-wrap: nowrap;       /* Prevent wrapping to new lines */
  @media (max-width: 678px) {
    font-size: 13px;
  }
}

.footer_links li {
  margin: 0.5rem 0.2rem;
  @media (min-width: 678px) {
    margin: 0.5rem 1rem;
  }
}

.footer_links a {
  color: #2a5934;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SMicons {
  color: rgb(73, 158, 93);  
  font-size: 20px;
  @media (min-width: 678px) {
    font-size: 25px;
  }
}

.footer_links {
  color: #000000;
}


