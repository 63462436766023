.Logo_wrapper {
    position:relative;
    margin-bottom: 50px;;
}

@keyframes popAnimation {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    70% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .Mainlogo {
    margin: 0 auto;
    position: absolute;
    width: 100%;
    animation: popAnimation 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);

  }

.Titel_top, .Titel_bottom {
    margin: 0 auto;
    font-weight: bold;
    letter-spacing: .25vw;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.Titel_bottom {
    padding-top: 30%;
}

.Welcome_text {
    text-align: center;
}

.Welcome_text a {
    text-decoration: none;
    color: #008650;
    font-weight: bolder;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.Welcome_text a:hover {
    text-decoration: underline;
}


 