/* Existing styles */
.description_Text {
    margin-bottom: 30px;
    padding: 20px;
}

.questionContainer {
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 30px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 3px 3px 5px  rgb(208, 208, 208),
                -3px -3px 5px  rgb(208, 208, 208);
}

.questionText {
    margin-bottom: 15px;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.optionLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.optionLabel:hover {
    background-color: #f8f9fa;
}

.radioInput {
    margin-right: 10px;
}

.optionText {
    flex-grow: 1;
}

.feedbackIcon {
    margin-left: 10px;
    font-weight: bold;
}

.feedbackIcon.correct {
    color: #28a745;
}

.feedbackIcon.incorrect {
    color: #dc3545;
}

.submitContainer {
    margin-top: 30px;
    text-align: center;
}

.submitButton {
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    border: none;
    cursor: pointer;
}

.scoreDisplay {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.correctBackground {
    background-color: rgba(9, 255, 0, 0.37) !important;
}

.incorrectBackground {
    background-color: rgba(255, 0, 0, 0.562) !important;
}

.navButton:hover, .submitButton:hover, .contactButton:hover, .reviewButton:hover, .returnButton:hover {
    color: black;
}

.navButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.progressIndicator {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    color: #6c757d;
}

.rightAlignedButtons {
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.navButton {
    margin-right: auto;
  }

.navButton, .submitButton, .contactButton, .reviewButton, .returnButton {
  background-color: rgb(250, 181, 53);
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 3px 3px 5px rgb(208, 208, 208),
              -3px -3px 5px rgb(208, 208, 208);
}

.contactButton {
    background-color: rgb(73, 158, 93);
    text-decoration: none;
    display: inline-block;
}

.scoreBox {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.checkboxInput {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  
  .explanationBox {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-left: 4px solid #007bff;
    border-radius: 10px;
    animation: fadeIn 0.5s ease-in;
  }
  
  .explanationTitle {
    color: #007bff;
    margin: 0 0 8px 0;
    font-size: 1.1em;
  }
  
  .explanationText {
    margin: 0;
    line-height: 1.5;
    color: #333;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .optionsContainer {
    margin-bottom: 20px;
  }

  .resultsContainer {
    text-align: center;
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .resultsTitle {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 2rem;
  }
  
  .scoreDisplay {
    margin: 2rem 0;
  }
  
  .scoreCircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #f8f9fa;
    border: 8px solid #007bff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .scoreNumber {
    font-size: 3rem;
    font-weight: bold;
    color: #007bff;
    line-height: 1;
  }
  
  .scoreTotal {
    font-size: 1.5rem;
    color: #6c757d;
  }
  
  .resultMessage {
    font-size: 1.2rem;
    color: #495057;
    margin: 2rem 0;
    line-height: 1.6;
  }
  
  .actionButtons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  /* Animation for results page */
  .resultsContainer {
    animation: fadeIn 0.5s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .navigationButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
  
  .navigationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1rem;
    margin-top: 20px;
}

.consentNote {
  font-size: 0.9em;
  color: #666;
  font-style: italic;
  display: block;
  margin-top: 10px;
}