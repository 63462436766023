
  .faqItem {
    border-bottom: 1px solid #e5e7eb;
    padding: 1rem 0;
  }
  
  .faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: large;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: rgb(47, 72, 88);
  }
  
  .faqAnswer {
    margin-top: 0.5rem;
    color: #4b5563;
    font-size: medium;
    color: rgb(47, 72, 88);
  }

  .faqAnswer a {
    color: #3e4042; 
  }