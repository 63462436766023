.cookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
  animation: slideUp 0.5s ease-out;
}

.cookieContent {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.cookieContent h3 {
  margin: 0 0 15px 0;
  color: #333;
}

.cookieContent p {
  margin: 0 0 20px 0;
  color: #666;
  line-height: 1.5;
}

.cookieList {
  margin: 10px 0;
  padding-left: 20px;
  color: #666;
}

.cookieList li {
  margin-bottom: 5px;
}

.cookieNote {
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

.acceptButton, .declineButton, .essentialButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.acceptButton {
  background-color: rgb(73, 158, 93);
  color: white;
}

.declineButton {
  background-color: #6c757d;
  color: white;
}

.essentialButton {
  background-color: #17a2b8;
  color: white;
}

.acceptButton:hover {
  background-color: rgb(60, 130, 76);
}

.declineButton:hover {
  background-color: #5a6268;
}

.essentialButton:hover {
  background-color: #138496;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .cookieContent {
    padding: 15px;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .acceptButton, .declineButton, .essentialButton {
    width: 100%;
  }
}