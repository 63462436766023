
.parent{
    display: flex;
    margin:10px auto;
    margin-bottom: 20px;
    padding: 0;
    background-color: white;
    border: 10px solid white;
    border-radius: 10px;
    box-shadow: 3px 3px 5px  rgb(208, 208, 208),
                -3px -3px 5px  rgb(208, 208, 208);

    @media screen and (min-width: 1198px) {
        max-width: 900px;
        margin: 50px auto;
    }
}

.head {
    text-align: center;
    margin: 10px;
    color: rgb(250, 181, 53);
    font-size: x-large;
    @media screen and (min-width: 1198px) {
        font-size: xx-large;        
    }
}

#level1 {
    background-color: rgb(175, 238, 175, 0.2);
    border-radius: 20px;
    margin-top: 10px;
    position:relative;
    @media screen and (min-width: 1198px) {
        margin-top: 30px;        
    }
}

#level2 {
    background-color: rgb(198, 198, 255, 0.2);
    border-radius: 20px;
    position:relative;
}

#level3 {
    background-color: rgb(255, 191, 191, 0.2);
    border-radius: 20px;
    margin-bottom: 30px;
    position:relative;
}

.level_nr1, .level_nr2, .level_nr3  {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 50px;
    @media (min-width: 768px) {
        top: 28%;  
        right: -50px;      
    }
}
.level_nr1 {
    color: rgb(175, 238, 175);
}
.level_nr2 {
    color: rgb(198, 198, 255);
}
.level_nr3 {
    color: rgb(255, 191, 191);
}

.row, .row2 {
    margin: 0 0 50px 0;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1198px) {
        margin-bottom: 10px;           
    }
}

.row2 {
    margin: 0;
    
}

.box1, .box2a, .box2b, .box3 {
    display: flex;
    padding: 0;
    margin: 5px;
    justify-content: center;
}

.box1 {
    width: 100%;
}

.box2a {
    justify-content: right;
}

.box2b {
    justify-content: left;
}

.box2a, .box2b {
    flex: 40%;
    @media screen and (max-width: 1198px) {
        justify-content: center;
        flex: 100%;            
    }
}

.box3 {
    flex: 20%;
    @media screen and (max-width: 1198px) {
        flex: 50%;            
    }
}

.cardContainer {
    display: flex;
}

.course_link {
    display: flex;
    text-decoration: none;
    color: rgb(105, 105, 105);
}

.card {
    padding: 10px;
    margin: 0;
    background-color: rgb(255, 255, 255, 0.7);
    border: 1px solid rgb(208, 208, 208);
    border-radius: 20px;
    width: 195px;
    box-shadow: 2px 2px 5px rgb(208, 208, 208);
    transition: transform 0.1s ease-out;
    @media screen and (min-width: 1198px) {
        min-width: 210px;           
    }
}

.card_header {
    font-size: large;
}

.card:hover {
    transform: scale(1.1, 1.1);
}

.card:hover .more_course {
    visibility: visible;
}

.card_list {
    display: none;
}

@media screen and (min-width: 1198px) {
    .card_header {
        height: 55px;
        margin: auto;
        font-size: x-large;
    }
    .card_list {
        display: inline;
        margin: auto;
        padding: 0;
    }
    .card_list li{
        padding-left: 20px;;
    }
}

.zwischenAnker {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: aliceblue;
    @media screen and (min-width: 1198px) {
        display: none;
        
    }
}

.Anker_element {
    width: 10px;
    background-color: aqua;
}

.more_course {
    float: right;
    text-decoration: none;
    color: rgb(250, 181, 53);
    visibility: hidden;
    margin: 0;
    @media screen and (max-width: 1198px) {
        font-size: small;       
    }
}


.text_block_bottom {
    margin: 50px auto;
}

.Level1_title, .Level2_title, .Level3_title {
    width: 200px;
    margin: auto;
    border: 3px solid;
    border-radius: 20px;
    text-align: center;
    color: rgb(47, 72, 88);
}

.Level1_title {
    border-color: rgb(175, 238, 175, 0.5);
    background-color: rgb(175, 238, 175, 0.2);   
}

.Level2_title {
    border-color: rgb(198, 198, 255, 0.5);
    background-color: rgb(198, 198, 255, 0.2);
}

.Level3_title {
    border-color: rgb(255, 191, 191, 0.5);
    background-color: rgb(255, 191, 191, 0.2);   
}

.paragraph {
    margin:  5px  5px 10px 5px;
    font-size: larger;
    color: rgb(47, 72, 88);
    text-align: justify;
    font-size: large;
    @media screen and (min-width: 1198px) {
        font-size: x-large;
        margin-bottom: 30px;
    }
}

