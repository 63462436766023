.Course_page {
    display: flex;
    margin: 0;
    @media (min-width: 768px) {


    }
}

.Course_info {

    width: 80%;
    @media (max-width: 768px) {
        width: 50%;
    }   
}

.Info_top {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    @media (max-width: 768px) {
        flex-direction: column;     
        }
    @media (max-width: 1130px) {
        max-width: 100%;
        }
}

.Info_top_cols {
    margin: 0;
    min-width: 200px;

}

.Info_text {
    padding: 20px 10px 20px 0;
    text-align: justify;

      @media (max-width: 768px) {   
        width: 67vw;
        padding: 20px 10px;
        letter-spacing: normal;
      }
      @media (min-width: 768px) {


      }
      @media (min-width: 1024px) {
        
      }
}

.Course_image {
    padding: 0;
    margin-top: 50px;
    
    @media (max-width: 768px) {
        margin-top: 0px;
        position: relative;
        height: 150px;
        right: -40px;
        top: -25px;
    }
    @media (min-width: 768px) {
        scale: 1.4;
        position: relative;
        right: -100px;
        top: -90px;

    }
    @media (min-width: 1024px) {



    }
    @media (min-width: 1480px) {

    }
    @media (max-height: 950px) {

        @media (max-width: 768px) {


        }
        @media (min-width: 768px) {


        }
        @media (min-width: 1024px) {

        }
    }            
}

.Anfrage_button {
    text-align: center;
    align-items: center;
    transition: transform 1s ease;
    margin: 0;
    padding-bottom: 20px;
    @media (max-width: 768px) {
        margin-top: 0px;
    }
    @media (min-width: 1024px) {

    }
    @media (max-height: 1084px) {

        @media (max-width: 1024px) {

        }
    }

}

.Anfrage_button.popup {
opacity: 1;
}

.Anfrage_button a {
    margin: auto;
    text-decoration: none;
    white-space: nowrap;
    font-size: x-large;
    box-shadow: 2px 2px 5px rgba(0,0,0,.4);
    color: white;
    background-color: #008650;
    padding: 8px 40px;
    border-radius: 25px;
    transition: color 1s ease;
    @media (max-width: 768px) {
        font-size: medium;   
        padding: 5px 10px;     
    }
}

.Anfrage_button a:hover {
    color: black;
    }

    @keyframes popIn {
    0% {

        transform: scale(1);
    }
    50% {

        transform: scale(1.2);
        }
    100% {

        transform: scale(1);
    }
    }

.Anfrage_button.popup {
    animation: popIn 0.7s ease forwards;
    }

.bottom_buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.backtocourse, .backtofaq {
    @media (max-height: 1084px) {

        @media (max-width: 1024px) {

        }
    }
}

.backtocourse a, .backtofaq a{
    text-decoration: none;
    color: #008650;
    font-weight: bolder;
}

.backtofaq {
    text-align: right;
}