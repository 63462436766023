.description_Text {
    margin-bottom: 30px;
    padding: 20px;
}

.questionContainer {
    margin-bottom: 30px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
}

.questionText {
    margin-bottom: 15px;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.optionLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.optionLabel:hover {
    background-color: #f8f9fa;
}

.radioInput {
    margin-right: 10px;
}

.optionText {
    flex-grow: 1;
}

.feedbackIcon {
    margin-left: 10px;
    font-weight: bold;
}

.feedbackIcon.correct {
    color: #28a745;
}

.feedbackIcon.incorrect {
    color: #dc3545;
}

.submitContainer {
    margin-top: 30px;
    text-align: center;
}

.submitButton, .resetButton {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.submitButton:hover, .resetButton:hover {
    background-color: #0056b3;
}

.scoreDisplay {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.resetButton {
    background-color: #6c757d;
}

.resetButton:hover {
    background-color: #5a6268;
}

.correctBackground {
    background-color: rgba(9, 255, 0, 0.37) !important;
}

.incorrectBackground {
    background-color: rgba(255, 0, 0, 0.562) !important;
}
.scrollToTopButton {
    position: fixed;  /* Keeps the button fixed in place */
    bottom: 20px;     /* Distance from the bottom of the viewport */
    right: 20px;      /* Distance from the right of the viewport */
    padding: 15px;    /* Size of the button */
    background-color: #007BFF;  /* Background color */
    color: white;     /* Text color */
    border: none;     /* Remove default border */
    border-radius: 50%;  /* Makes the button round */
    cursor: pointer;  /* Pointer cursor on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);  /* Adds a subtle shadow for a 3D effect */
    z-index: 1000;    /* Ensures the button stays on top of other elements */
    transition: background-color 0.3s;  /* Smooth transition for background color */
  }
  
  .scrollToTopButton:hover {
    background-color: #0056b3;  /* Darker shade on hover */
  }
  