.speech-bubble {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* overflow: hidden; Remove this if it clips the triangle */
 }
 
 .speech-bubble::before {
    content: "";
    position: absolute;
    top: -6px; /* Adjust as needed */
    left: 20%;
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: -1;
 }
 
 .no-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
 }
 